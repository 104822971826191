body {
  font-family: 'IBM Plex Sans Condensed', sans-serif;
  background-color: #181a1f;
  color: #ffffff;
  padding: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: #22252a;
  margin-top: 20px;
}

thead {
  background-color: #7a7e89;
}

th {
  font-weight: 400;
  color: #f1f1f1;
  text-transform: uppercase;
  padding: 12px 16px;
  border-bottom: 2px solid #2a2e35;
}

th,
td {
  padding: 12px 16px;
  border-bottom: 1px solid #2a2e35;
  text-align: left;
}

th {
  font-weight: 500;
}

img {
  max-width: 32px;
  height: auto;
  border-radius: 50%;
}

input,
select,
button {
  padding: 10px;
  margin-right: 10px;
  margin-top: 20px; /* New margin added */
  margin-left: 20px; /* New margin added */
  border-radius: 4px;
}

input {
  min-width: 400px;
}

input,
select {
  border: none;
  background-color: #2a2e35;
  color: #ffffff;
}

button {
  background-color: #30353b;
  border: none;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #3a3f45;
}

select:focus,
input:focus,
button:focus {
  outline: none;
}

.loader {
  border: 8px solid #1c1e23;
  border-top: 8px solid #f1f1f1;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}
